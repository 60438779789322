<template>
    <div id="index" class="enterprise-site-screen-container">
    <img class="bg-img" src="../../../assets/screen/background.png" />
    <!-- 顶部 start -->
    <img class="logo-img" src="../../../assets/screen/logo_screen.png" />
    <div class="logoDiv">{{ filterCompanyName(curCompany.companyName) }}</div>
    <el-dropdown trigger="click" class="qiehuan" style="min-width: 220px; display:inline-block" @command="handleChangeCompanyCommand" placement="top-start">
      <span class="el-dropdown-link">
        <i class="icon-ic_standard" />
        {{ curCompany.companyName }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown" style="max-height:320px; overflow:auto">
        <el-dropdown-item :command="company" v-for="company in companyList" :key="company.companyId">
          <span>{{ company.companyName }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- 顶部 end -->

    <div class="bg-img-container" style="z-index: 0;">
      <!-- 左侧盒子-->
      <div class="left">
        <!-- <div id="overageWarningChart" style="width: 200px; height: 200px;"></div> -->
        <div class="box-container" style="height: 297px">
          <div class="title"><span>动火作业动态统计</span></div>
          <div class="content" style="display: flex; flex-direction: row">
            <div style="flex:1; border-right: 1px dashed rgba(0, 145, 255, 0.4); padding-right:9px; margin-right: 9px;">
              <el-table
                :data="tableData1"
                header-cell-class-name="header-cell-class"
                stripe
                :row-class-name="tableRowClassName"
                >
                <el-table-column prop="name" label="类型">
                  <template v-slot="scope">
                    <span :style="{'color': filterLevelColor(scope.row.name) }">{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="num" label="签发">
                </el-table-column>
              </el-table>
            </div>
            <div style="flex:2">
              <el-table
                :data="tableData2"
                stripe
                header-cell-class-name="header-cell-class"
                >
                <el-table-column prop="name" label="类型">
                </el-table-column>
                <el-table-column prop="num" label="作业中">
                </el-table-column>
                <el-table-column prop="num2" label="已结束">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="box-container" style="height: 297px">
          <div class="title"><span>作业人员管理</span></div>
          <div class="content">
            <div class="alarm-container">
              <div class="tab">
                <img src="../../../assets/screen/icon_yujing.png"><span class="alarm-name">超龄预警</span> <span class="alarm-num">{{operatorManageInfo.overAgeWarningCount}}</span> <span class="alarm-name">人</span>
              </div>
              <div class="tab">
                <img src="../../../assets/screen/icon_yujing.png"><span class="alarm-name">证件延期提示</span> <span class="alarm-num">{{operatorManageInfo.certExpiryWarningCount}}</span> <span class="alarm-name">人</span>
              </div>
            </div>
          </div>
          <div class="alarm-chart-container" style="display: flex;">
            <div style="flex:1;">
              <div id="overageWarningChart" style="height: 169px;"></div>
            </div>
            <div style="flex:1; height: 169px;">
              <div id="certificateDelayChart" style="height: 169px;"></div>
            </div>
          </div>
        </div>
        <div class="box-container" style="height: 297px">
          <div class="title"><span>本周作业活动分析</span></div>
          <div class="content">
            <div id="radarChart" style="height: 169px;"></div>
          </div>
        </div>
      </div>

      <!-- 中间盒子-->
      <div class="center">
        <ul class="tab-container">
          <li :class="current===0 ? 'selected' : ''"><span @click="changeTab(0)">地图看板</span></li>
          <li :class="current===1 ? 'selected' : ''"><span @click="changeTab(1)">数据看板</span></li>
          <li :class="current===2 ? 'selected' : ''"><span @click="changeTab(2)">视频监护</span></li>
        </ul>
        <div class="box-container" v-show="current===1" style="height: 221px">
          <div class="title"><span>本周作业活动分析</span></div>
          <div class="content" style="height: 191px">
            <week-item
              :honor-name="`今日出勤最多的${curCompany.hasSubcompany ? '公司' : '项目'}`"
              :count="weekFireWorkAnalysis.attendanceCount"
              :total-count="weekFireWorkAnalysis.attendanceTotalCount"
              :companyOrProject="weekFireWorkAnalysis.attendanceCompanyOrProjectName"
              >
            </week-item>
            <week-item
              honor-name="动火作业最多的项目"
              :count="weekFireWorkAnalysis.hotWorkCount"
              :total-count="weekFireWorkAnalysis.hotWorkTotalCount"
              :companyOrProject="weekFireWorkAnalysis.hotWorkCompanyOrProjectName"
              >
            </week-item>
            <week-item
              :honor-name="`作业票最多的${curCompany.hasSubcompany ? '公司' : '项目'}`"
              :count="weekFireWorkAnalysis.hotWorkPermitCount"
              :total-count="weekFireWorkAnalysis.hotWorkPermitTotalCount"
              :companyOrProject="weekFireWorkAnalysis.hotWorkPermitCompanyOrProjectName"
              >
            </week-item>
            <week-item
              :honor-name="`焊工最多的${curCompany.hasSubcompany ? '公司' : '项目'}`"
              :count="weekFireWorkAnalysis.welderCount"
              :total-count="weekFireWorkAnalysis.welderTotalCount"
              :companyOrProject="weekFireWorkAnalysis.welderCompanyOrProjectName"
              >
            </week-item>
          </div>
        </div>
        <div class="box-container" v-show="current===1" style="height: 297px">
          <div class="title">
            <span>机器人分布</span>
          </div>
          <div class="content">
            <div style="width: 100%; height: 192px" v-if="curCompany.companyId === '6223154483595266'">
              <div style="margin-bottom:18px; display:flex; flex-direction: row;">
                <div style="width: 180px; text-align: right; padding-right:10px; color:#fff;">上海市安装集团第三工程公司</div>
                <div style="flex:1"><el-progress :text-inside="true" :stroke-width="18" :percentage="countPercent(weekWorkList[0].value, weekWorkList[0].sum)" :format="formatRobotDistribute(weekWorkList[0].value)"></el-progress></div>
                <div style="width: 220px; padding-left:10px; color:#fff"><img src="../../../assets/icon-robot-new.png" width="22" style="cursor: pointer" />（<span style="color: #34FFBF">{{weekWorkList[0].value}}</span>/{{weekWorkList[0].sum}}）</div>
              </div>
              <div style="margin-bottom:18px; display:flex; flex-direction: row;">
                <div style="width: 180px; text-align: right; padding-right:10px; color:#fff;">上海市安装集团第三工程公司</div>
                <div style="flex:1"><el-progress :text-inside="true" :stroke-width="18" :percentage="countPercent(weekWorkList[1].value, weekWorkList[1].sum)" :format="formatRobotDistribute(weekWorkList[1].value)"></el-progress></div>
                <div style="width: 220px; padding-left:10px; color:#fff"><img src="../../../assets/icon-robot-new.png" width="22" style="cursor: pointer" />（<span style="color: #34FFBF">{{weekWorkList[1].value}}</span>/{{weekWorkList[1].sum}}）</div>
              </div>
              <div style="margin-bottom:18px; display:flex; flex-direction: row;">
                <div style="width: 180px; text-align: right; padding-right:10px; color:#fff;">上海市安装集团第三工程公司</div>
                <div style="flex:1"><el-progress :text-inside="true" :stroke-width="18" :percentage="countPercent(weekWorkList[2].value, weekWorkList[2].sum)" :format="formatRobotDistribute(weekWorkList[2].value)"></el-progress></div>
                <div style="width: 220px; padding-left:10px; color:#fff"><img src="../../../assets/icon-robot-new.png" width="22" style="cursor: pointer" />（<span style="color: #34FFBF">{{weekWorkList[2].value}}</span>/{{weekWorkList[2].sum}}）</div>
              </div>
              <div style="margin-bottom:18px; display:flex; flex-direction: row;">
                <div style="width: 180px; text-align: right; padding-right:10px; color:#fff;">上海市安装集团第三工程公司</div>
                <div style="flex:1"><el-progress :text-inside="true" :stroke-width="18" :percentage="countPercent(weekWorkList[3].value, weekWorkList[3].sum)" :format="formatRobotDistribute(weekWorkList[3].value)"></el-progress></div>
                <div style="width: 220px; padding-left:10px; color:#fff"><img src="../../../assets/icon-robot-new.png" width="22" style="cursor: pointer" />（<span style="color: #34FFBF">{{weekWorkList[3].value}}</span>/{{weekWorkList[3].sum}}）</div>
              </div>
              <div style="margin-bottom:18px; display:flex; flex-direction: row;">
                <div style="width: 180px; text-align: right; padding-right:10px; color:#fff;">上海市安装集团第三工程公司</div>
                <div style="flex:1"><el-progress :text-inside="true" :stroke-width="18" :percentage="countPercent(weekWorkList[3].value, weekWorkList[3].sum)" :format="formatRobotDistribute(weekWorkList[3].value)"></el-progress></div>
                <div style="width: 220px; padding-left:10px; color:#fff"><img src="../../../assets/icon-robot-new.png" width="22" style="cursor: pointer" />（<span style="color: #34FFBF">{{weekWorkList[3].value}}</span>/{{weekWorkList[3].sum}}）</div>
              </div>
            </div>
            <div style="width: 100%; height: 192px" v-else>
              <div class="robot-line" v-for="(item,i) in robotList" :key="i">
                <div class="label">{{ item.projectName }}</div>
                <div class="list">
                    <el-tooltip placement="top"
                      popper-class="robot-popper-info"
                      v-for="(robot,j) in item.robots"
                      :key="j"
                      effect="light">
                      <div slot="content">
                        设备名称：{{ robot.deviceName }}<br/>动火点名称：{{ robot.pointName }}<br/>操作类型：{{ robot.type }}<br/>最近一次使用：{{ robot.dateTime }}
                      </div>
                      <div :class="robot.status===0 ? 'outline black-and-white' : 'outline'">
                        <img src="../../../assets/icon-robot-new.png" width="30" style="cursor: pointer" :class="robot.status===0 ? 'black-and-white' : ''" />
                      </div>
                    </el-tooltip>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        
        <div class="box-container" v-show="current===0" style="padding:0; height: 536px">
          <baidu-map
              class="map"
              :center="center"
              :zoom="zoom"
              :scroll-wheel-zoom="true"
              :continuous-zoom="true"
              @ready="handler">
            <template>
              <bm-marker :position="item" v-for="(item, index) in newPoints" :key="index" @click="look(item)"></bm-marker>
              <bm-info-window :title="from.projectName" :position="from.position" :show="showInfoWindow" @close="infoWindowClose" @open="infoWindowOpen()">
                <div style="color:#fff; font-size:16px;">
                  <div style="margin-top:5px; height: 2px; width:100%;background-color: #1981E1 !important;"></div>
                  <div style="margin-top:10px;">建设单位： {{from.buildingCompany}} </div>
                  <div style="margin-top:5px;">项目经理：{{from.projectLeader}}</div>
                  <div style="margin-top:5px;">计划开工日期：{{from.startDate | format('YYYY-MM-DD')}}</div>
                  <div style="margin-top:5px;">计划竣工日期：{{from.completeDate | format('YYYY-MM-DD')}}</div>
                </div>
                <el-button size="mini" class="enterButton" @click="jumpToProjectScreen(from)">进入</el-button>
              </bm-info-window>
            </template>
            <bm-map-type :map-types="['BMAP_NORMAL_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
          </baidu-map>
        </div>
        <div class="box-container" v-show="current===2" style="height: 536px">
          <div class="title" style="display: flex">
            <span style="width: 150px; display: block">视频看板</span>
            <div style="height: 16px; margin-right: 0; top: -16px; flex:1; display: inline-block; text-align: right;">
              <span style="cursor: pointer; margin-right: 15px;" :type="classtype1"  @click="getVideoNumInScreen(1)">
                <el-tooltip effect="dark" content="1分屏" placement="bottom">
                  <img src="../../../assets/1ping.svg" v-if="classtype1!='primary'">
                  <img src="../../../assets/1pingYellow.svg" v-if="classtype1=='primary'">
                </el-tooltip>
              </span>
                <span style="cursor: pointer; margin-right: 10px" :type="classtype2"  @click="getVideoNumInScreen(2)">
                <el-tooltip effect="dark" content="4分屏" placement="bottom">
                  <img src="../../../assets/4ping.svg" v-if="classtype2!='primary'">
                  <img src="../../../assets/4pingYellow.svg" v-if="classtype2=='primary'">
                </el-tooltip>
              </span>
                <span style="cursor: pointer; margin-right: 15px" :type="classtype3"  @click="getVideoNumInScreen(3)">
                <el-tooltip effect="dark" content="9分屏" placement="bottom">
                  <img src="../../../assets/9ping.svg" v-if="classtype3!='primary'">
                  <img src="../../../assets/9pingYellow.svg" v-if="classtype3=='primary'">
                </el-tooltip>
              </span>
                <span style="cursor: pointer; margin-right: 15px" :type="classtype4"  @click="getVideoNumInScreen(4)">
                <el-tooltip effect="dark" content="16分屏" placement="bottom">
                  <img src="../../../assets/16ping.svg" v-if="classtype4!='primary'">
                  <img src="../../../assets/16pingYellow.svg" v-if="classtype4=='primary'">
                </el-tooltip>
              </span>
            </div>
          </div>
          
          <div class="content">
            <div class="hotwork_video_content" id="container">
              <div class="hotwork_video_main">
                <el-carousel :interval="6000" :height="playerHeight+'px'">
                  <el-carousel-item v-for="pitem in newVideoList.length" :key="pitem">
                    <el-row class="grid_region" gutter="1">
                      <el-col :span="rowNum" v-for="(item,index) in newVideoList[pitem-1]" :key="index">
                        <div class="video" ref="video_area" v-if="item.webrtcUrl && item.webrtcUrl!==''" style="position:relative">
                          <div :style="{'width':'100%','background-color':'#000', 'border':'solid 1px #fff','height':playerHeight/heightRatio+'px','line-height':playerHeight/heightRatio+'px'}">
                            <LivePlayer :videoUrl="item.webrtcUrl" fluent autoplay live stretch></LivePlayer>
                          </div>
                          <div class="block" style="min-width:60px; z-index:999; position:absolute; left:5px; top:0; color:#0ff; font-size:12px; text-align:center">
                            <span class="demonstration" style="line-height:21px;">{{ item.projectAbbreviation }}：{{item.label}}</span>
                          </div>
                        </div>
                        <div :style="{'width':'100%','background-color':'#000', 'border':'solid 1px #fff','height':playerHeight/heightRatio+'px','line-height':playerHeight/heightRatio+'px'}" v-else>
                          <i class="el-icon-video-camera"></i>
                        </div>
                      </el-col>
                      <template v-if="newVideoList[pitem-1].length<pageSize">
                        <el-col :span="rowNum" v-for="(bgitem,i) in pageSize-currentVideoList.length" :key="i">
                          <div :style="{'background-color':'#000', 'border':'solid 1px #fff','height':playerHeight/heightRatio+'px','line-height':playerHeight/heightRatio+'px'}">
                            <i class="el-icon-video-camera"></i>
                          </div>
                        </el-col>
                      </template>
                    </el-row>
                  </el-carousel-item>
                </el-carousel>

              </div>
            </div>
          </div>
        </div>
        <div class="box-container" style="height: 297px">
          <div class="title">
            <span>作业分析</span>
            <div style="width: 120px; float: right">
              <div class="centerTimeTabs">
                <div :class="zyActive==0 ? 'selected' : 'tab'" @click="changeZyTab(0)">月</div>
                <div :class="zyActive==1 ? 'selected' : 'tab'" @click="changeZyTab(1)">年</div>
              </div>
            </div>
            <el-select v-model="curMonth" placeholder="请选择" class="xlcd" size="mini" :popper-append-to-body="false" v-if="zyActive===0">
              <el-option
                v-for="(item,index) in monthList"
                :key="index"
                :label="item"
                :value="index+1"
                :disabled="index+1>curMonth">
              </el-option>
            </el-select>
          </div>
          <div class="content">
            
            <div id="yearBarChart" style="width: 100%; height: 192px" v-if="zyActive===0"></div>
            <ul class="tab-normal-container" v-if="zyActive===1">
              <li
                :class="tCurrent===i ? 'selected' : ''"
                v-for="(typeItem,i) in gcList"
                :key="i">
                <span @click="changeTab2(i)">{{typeItem.name}}</span>
              </li>
            </ul>
            <div id="stackedAreaChart" style="width: 100%; height: 189px" v-if="zyActive===1"></div>
          </div>
          
        </div>
      </div>

      <!-- 右侧盒子-->
      <div class="right">
        <div class="box-container" style="height: 297px">
          <div class="title"><span>"动火机器人"管理 </span></div>
          <div class="content" style="display: flex; flex-direction: row;">
            <img src="../../../assets/screen/icon_big.png" width="185" height="200" />
            <ul class="robot-count-container" style="flex: 1; ">
              <li class="total">
                <img src="../../../assets/screen/icon_point.png" width="14" height="10" />
                <span class="label">设备总数</span>
                <span class="num">{{ deviceStatis.onLineCount }}/{{deviceStatis.totalCount}}</span>
                <span class="unit">台</span>
              </li>
              <li>
                <img src="../../../assets/screen/icon_point_select.png" width="14" height="10" />
                <span class="label">项目数量</span>
                <span class="num">{{deviceStatis.count}}</span>
                <span class="unit">个</span>
              </li>
              <li>
                <img src="../../../assets/screen/icon_point_select.png" width="14" height="10" />
                <span class="label">月使用率</span>
                <span class="num">{{deviceStatis.usageRate}}</span>
                <span class="unit">%</span>
              </li>
              <li>
                <img src="../../../assets/screen/icon_point_select.png" width="14" height="10" />
                <span class="label">月服务时长</span>
                <span class="num">{{deviceStatis.serviceTime}}</span>
                <span class="unit">小时</span>
              </li>
              <li>
                <img src="../../../assets/screen/icon_point_select.png" width="14" height="10" />
                <span class="label">月监管点位</span>
                <span class="num">{{deviceStatis.firePointCount}}</span>
                <span class="unit">个</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="box-container" style="height: 297px">
          <div class="title">
            <span>告警统计</span>
            <div style="width: 120px; float: right">
              <div class="timeTabs">
                <div :class="active==1 ? 'selected' : 'tab'" @click="changeTimeTab(1)">今日</div>
                <div :class="active==2 ? 'selected' : 'tab'" @click="changeTimeTab(2)">本月</div>
                <div :class="active==3 ? 'selected' : 'tab'" @click="changeTimeTab(3)">本年</div>
              </div>
            </div>
          </div>
          <div class="content">
            <div id="todayAlarm_chart" style="width: 90%; height: 219px"></div>
          </div>
        </div>
        <div class="box-container" style="height: 297px">
          <div class="title"><span>重点管控区域</span></div>
          <div class="content">
            <el-row>
              <el-col :span="12" v-for="(keyArea,index) in keyAreaList" :key="index">
                <area-item :info="keyArea" :style="{'margin-left': (index+1)%2==0 ? '8px' : '0'}"></area-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LivePlayer from '@liveqing/liveplayer'
import AreaItem from './components/screen/area-item.vue'
import WeekItem from './components/screen/week-item.vue'

export default {
    name: 'companyScreen',
    components: {
        LivePlayer,
        AreaItem,
        WeekItem,
    },
    data() {
        return {
            zyActive: 0,
            robotList:[{
              projectName: '虹桥机场消防工程',
              robots:[{
                deviceName: 'DK00134',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00141',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00154',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00165',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00178',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00189',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00112',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00199',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00211',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 0,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00985',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 0,
                dateTime: '2024-07-28'
              }]
            },{
              projectName: '上海大歌剧院项目',
              robots:[{
                deviceName: 'DK00134',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00134',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              }]
            },{
              projectName: '莘庄上盖二期',
              robots:[{
                deviceName: 'DK00134',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00134',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              }]
            },{
              projectName: '临港文创园',
              robots:[{
                deviceName: 'DK00134',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00134',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              }]
            },{
              projectName: '银联项目',
              robots:[{
                deviceName: 'DK00134',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              },{
                deviceName: 'DK00134',
                pointName: '地下一层加工区',
                type:'电焊',
                status: 1,
                dateTime: '2024-07-28'
              }]
            }],
            active: 1,
            // part 3 视频
            playerHeight:'',
            newVideoList:[],
            heightRatio:1,
            currentVideoList:[], // 当前项目
            classtype1:'',
            classtype2:'primary',
            classtype3:'',
            classtype4:'',
            // part 2
            newPoints:[],
            from:{},
            center: {lng: 121.403573, lat: 31.244107},
            zoom: 12,
            showInfoWindow: false,
            // part 1
            curMonth: '',
            tableData1: [{
                name: "一级",
                num: 0
            },{
                name: "二级",
                num: 0
            },{
                name: "三级",
                num: 0
            },{
                name: "合计",
                num: 0
            }],
            tableData2:[{
                name: "砂轮切割",
                num: 0,
                num2: 0
            },{
                name: "电焊",
                num: 0,
                num2: 0
            },{
                name: "气割",
                num: 0,
                num2: 0
            },{
                name: "其他",
                num: 0,
                num2: 0
            }],
            overageWarningList:[
            // {
            //     name: "55周岁以上",
            //     value: 5
            // },{
            //     name: "其他",
            //     value: 200
            // }
            ],
            certificateDelayList:[
            //   {
            //     name: "特操证",
            //     value: 180
            // },{
            //     name: "其他",
            //     value: 20
            // }
          ],
            curCompany: {},
            gcColorList: ["#FFDD1E", "#34FFBF", "#FF645D", "#00FAFE", "#0091FF"],
            gcList: [{
                name: "作业人员活跃度",
                value: [120, 132, 101, 134, 90, 230, 210, 132, 101, 134, 90, 110]
            },{
                name: '作业作业闭环率',
                value:[220, 182, 191, 234, 290, 330, 310, 182, 191, 234, 290, 330]
            },{

                name: '人均出勤天数',
                value: [150, 232, 201, 154, 190, 330, 410, 232, 201, 154, 190, 330]
            },{
                name: '审核率',
                value: [320, 332, 301, 334, 390, 330, 320, 332, 301, 334, 390, 330]
                },{
                name: '人员合规',
                value: [820, 932, 901, 934, 1290, 1330, 1320, 932, 901, 934, 1290, 1330]
            }],
            // '上海俊令建筑劳务有限公司', '上海川平设备安装工程有限公司', '上海熙凯建设工程有限公司', '上海东服建筑有限公司'
            weekWorkList:[{
                name: "上海俊令建筑劳务有限公司",
                value: 35,
                sum: 120,
                countType: '今日出勤最多的公司',
            },{
                name: "上海川平设备安装工程有限公司",
                value: 135,
                sum: 200,
                countType: '动火作业最多的项目',
            },{
                name: "上海熙凯建设工程有限公司",
                value: 35,
                sum: 120,
                countType: '作业票最多的公司',
            },{
                name: "上海东服建筑有限公司",
                value: 35,
                sum: 120,
                countType: '焊工最多的公司',
            }],
            keyAreaList:[],
            keyAreaHotworkList:[{
                type: 1,
                typeName: "立面动火",
                companyName: "上海俊令建筑劳务",
                typeNum: 50,
                companyNum: 20
            },{
                type: 2,
                typeName: "受限空间",
                companyName: "上海川平设备安装工程",
                typeNum: 50,
                companyNum: 20
            },{
                type: 3,
                typeName: "机房",
                companyName: "上海煦凯建设工程",
                typeNum: 50,
                companyNum: 20
            },{
                type: 4,
                typeName: "加工区",
                companyName: "上海东服建筑老茹",
                typeNum: 50,
                companyNum: 20
            }],
            companyId: '001',
            current: 0, // 主菜单切换： 地图看板、数据看板、视频监控
            tCurrent: 0, // 工程公司列表tab
            monthList: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            // 新的开始
            companyList: [],
            radarList: [
                {
                value: [4200, 3000, 20000, 35000, 500],
                name: 'Allocated Budget',
                areaStyle: { // 相应的区域样式
                    color: '#8bc7fb',
                    opacity: 0.5
                },
                itemStyle:{ // 点的颜色
                    color: '#fff', // 点的中间颜色
                    borderColor:'#187dd2', // 点的边框颜色
                },
                },
                // {
                //   value: [5000, 14000, 28000, 26000],
                //   name: 'Actual Spending'
                // }
            ],
            todayAlarmList:[],
            deviceStatis: {
                count: 0,
                firePointCount: 0,
                serviceTime: 0,
                totalCount: 0,
                usageRate: 0,
            },
            timeType: 'day',
            timeOptions: [{
              label: '日',
              value: 'day'
            },{
              label: '月',
              value: 'month'
            },{
              label: '年',
              value: 'year'
            },],
            operatorManageInfo:{},
            weekFireWorkAnalysis:{}
        }
    },
    mounted(){
      this.curMonth = this.$moment().month()+1
      this.getCompanyList()
    },
    methods: {
      // 切换时间: 3 年 2 月 1 日
      changeZyTab(val){
        console.log(val)
        this.zyActive = val
        if(this.zyActive===0){
          this.$nextTick(() => { // 修正图表显示时的宽度自适应
            this.getYearBarChart()
          });
        }else{
          this.$nextTick(() => { // 修正图表显示时的宽度自适应
            this.getStackedAreaChart(this.tCurrent)
          });
          
        }
      },
      // 切换时间: 3 年 2 月 1 日
      changeTimeTab(val){
        this.active = val
        this.getTodayAlarm()
      },
        // 获取"动火机器人"管理信息
    getDeviceStatis(){
      let data = {
        'companyId': this.curCompany.companyId,
        'projectCode':''
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/device/statis', data).then(res => {
        if (res.code === 200) {
          this.deviceStatis.firePointCount = res.data.firePointCount
          this.deviceStatis.serviceTime = res.data.serviceTime
          this.deviceStatis.totalCount = res.data.totalCount
          this.deviceStatis.usageRate = res.data.usageRate
          this.deviceStatis.count = res.data.count
          this.deviceStatis.onLineCount = res.data.onLineCount || 0
        }
      })
    },
    filterLevelColor(val){
      if(val==='一级') return '#55E371'
      if(val==='二级') return '#F7D720'
      if(val==='三级') return '#F6615D'
    },
    // 获取公司列表
    getCompanyList(){
      let data = {
        'companyId': "6223154483595266"
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/project/statis', data).then(res => {
        if (res.code === 200) {
          if(res.data.companyId){
            this.companyList.push({
              companyId: res.data.companyId,
              companyName: res.data.companyName,
              hasSubcompany: res.data.subCompanyStatisInfoList && res.data.subCompanyStatisInfoList.length>0 ? true : false
            })
            
            if(res.data.subCompanyStatisInfoList && res.data.subCompanyStatisInfoList.length>0){
              res.data.subCompanyStatisInfoList.forEach(company => {
                this.companyList.push({
                  companyId: company.companyId,
                  companyName: company.companyName,
                  hasSubcompany: company.subCompanyStatisInfoList && company.subCompanyStatisInfoList.length>0 ? true : false
                })
              })
            }
          }
          this.curCompany = this.companyList[0]
          console.log(this.curCompany.companyId)
          this.publicData()
          
          this.getYearBarChart()
          
          
          this.getRadarChart()
          
          

          // 获取地图看板数据
          this.getPoint()
        }
      }
      ).catch(error => {
        console.log('错误' + error)
      })
    },
    // 获取作业人员管理信息
    getOperatorManage(){
      let data = {
        "companyId": this.curCompany.companyId
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/operator/manage', data).then(res => {
        console.log(res)
        if(res.code === 200){
          console.log(res.data)
          this.operatorManageInfo = res.data
          this.overageWarningList = [
            {
                name: "特操证",
                value: res.data.certCount
            },{
                name: "其他",
                value: res.data.totalCount - res.data.certCount	
            }
          ]
          this.certificateDelayList = [
              {
                name: "特操证",
                value: res.data.certAttendanceCount
            },{
                name: "其他",
                value: res.data.attendanceTotalCount - res.data.certAttendanceCount
            }
          ]
          this.getOverageWarning()
          this.getCertificateDelayWarning()
          
        }
      })
    },
    // 获取重点管控区域信息
    getKeyArea(){
      let data = {
        "companyId": this.curCompany.companyId
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/key/area', data).then(res => {
        console.log(res)
        if(res.code === 200){
          console.log(res)
          this.keyAreaList = res.data.keyAreaList || []
        }
      })
    },
    // 动火作业动态统计
    getHotworkDynamicCount(){
      let data = {
        'companyId': this.curCompany.companyId,
        'projectCode':''
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/fireWork/statis', data).then(res => {
        console.log(res)
        if(res.code === 200){
          this.tableData1=[{
            name: "一级",
            num: res.data.levelOneCount
          },{
            name: "二级",
            num: res.data.levelTwoCount
          },{
            name: "三级",
            num: res.data.levelThreeCount
          },{
            name: "合计",
            num: res.data.totalCount
          }]
          this.tableData2=[{
            name: "砂轮切割",
            num: res.data.abrasiveCuttingInfo.underwayCount,
            num2: res.data.abrasiveCuttingInfo.completedCount
          },{
            name: "电焊",
            num: res.data.electricWeldingInfo.underwayCount,
            num2: res.data.electricWeldingInfo.completedCount
          },{
            name: "气割",
            num: res.data.gasCuttingInfo.underwayCount,
            num2: res.data.gasCuttingInfo.completedCount
          },{
            name: "其他",
            num: res.data.otherInfo.underwayCount,
            num2: res.data.otherInfo.completedCount
          }]
        }
      })
      
    },
    // 获取视频列表
    getVideo() {
      let data = {
        "companyId": "6223154483595266"
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/project/list', data).then(res => {
            if (res.code === 200) {
              this.videoList=[]
              let arr=[]
              console.log(res.data.projectsAndHotwork)
              if(res.data.projectsAndHotwork && res.data.projectsAndHotwork.length>0){
                res.data.projectsAndHotwork.forEach(item=>{
                  if(item.hotWorkPermits && item.hotWorkPermits.length>0){
                    item.hotWorkPermits.forEach(subItem=>{
                      arr.push({
                        ...subItem,
                        projectName: item.projectName,
                        projectAbbreviation: item.projectAbbreviation
                      })
                    })
                  }
                })
              }
              
              console.log('视频列表获取~')
              console.log(arr)
              for(let item of arr){
                if(item.flvUrl && item.flvUrl!==''){
                  this.videoList.push({
                    id: item.hotWorkPermitCode,
                    label: item.hotWorkPointName,
                    deviceCode: item.deviceCode,
                    webrtcUrl: item.flvUrl,
                    alarmVolumn: 100,
                    projectName: item.projectName,
                    projectAbbreviation: item.projectAbbreviation
                  })
                }

              }
              this.total = this.videoList.length
              this.getVideoNumInScreen(1)

            }
          }
      ).catch(error => {
        console.log('错误' + error)
      })
    },
    // part3 视频
    // val 画面 1：1，2：4，3：9，4：16
    getVideoNumInScreen(val){
      this.heightRatio=val
      this.playerHeight=document.getElementById("container").offsetWidth/val*454/807 * this.heightRatio;
      // console.log(val);
      this.newVideoList=[]
      this.rowNum = 24/val;
      this.pageSize = Math.pow(val, 2)
      // console.log("pageSize:"+this.pageSize)
      this.pageNum = Math.ceil(this.total/this.pageSize); // 平方再向上取整
      for(let i=0;i<=this.pageNum-1;i++){
        // console.log("i:"+i)
        // console.log(this.pageSize)
        let remain= i==this.pageNum-1 ? (this.videoList.length%this.pageSize==0?this.pageSize :  this.videoList.length%this.pageSize): this.pageSize
        // console.log("remain:"+remain)
        let list=[]
        for(let j=0; j<=remain-1;j++){
          // console.log("j:"+j)
          // console.log(i,j,i*this.pageSize+j)
          list.push(this.videoList[i*this.pageSize+j])

        }
        this.newVideoList[i]=list
      }


      if(val==1){
        this.classtype1='primary'
        this.classtype2=''
        this.classtype3=''
        this.classtype4=''
      }
      else if(val==2){
        this.classtype1=''
        this.classtype2='primary'
        this.classtype3=''
        this.classtype4=''
      }
      else if(val==3){
        this.classtype1=''
        this.classtype2=''
        this.classtype3='primary'
        this.classtype4=''
      }
      else if(val==4){
        this.classtype1=''
        this.classtype2=''
        this.classtype3=''
        this.classtype4='primary'
      }
    },
    // part2 地图
    
    // 地图ready事件
    handler ({BMap, map}) {
        let that = this;
        that.map = map;
        map.setMapStyleV2(
            {
              // styleId:'73979e40c609f11f44227183a85a0f42'
              styleJson:[{
                "featureType": "land",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#091220ff"
                }
              }, {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#113549ff"
                }
              }, {
                "featureType": "green",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#0e1b30ff"
                }
              }, {
                "featureType": "building",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "building",
                "elementType": "geometry.topfill",
                "stylers": {
                  "color": "#113549ff"
                }
              }, {
                "featureType": "building",
                "elementType": "geometry.sidefill",
                "stylers": {
                  "color": "#143e56ff"
                }
              }, {
                "featureType": "building",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#dadada00"
                }
              }, {
                "featureType": "subwaystation",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#113549B2"
                }
              }, {
                "featureType": "education",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#12223dff"
                }
              }, {
                "featureType": "medical",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#12223dff"
                }
              }, {
                "featureType": "scenicspots",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#12223dff"
                }
              }, {
                "featureType": "highway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "weight": "4"
                }
              }, {
                "featureType": "highway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "highway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#fed66900"
                }
              }, {
                "featureType": "highway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "highway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "highway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "highway",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "arterial",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "weight": "2"
                }
              }, {
                "featureType": "arterial",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "arterial",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffeebb00"
                }
              }, {
                "featureType": "arterial",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "arterial",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "arterial",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "local",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "weight": "1"
                }
              }, {
                "featureType": "local",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "local",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "local",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "local",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#979c9aff"
                }
              }, {
                "featureType": "local",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffffff"
                }
              }, {
                "featureType": "railway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "subway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "weight": "1"
                }
              }, {
                "featureType": "subway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#d8d8d8ff"
                }
              }, {
                "featureType": "subway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "subway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "subway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#979c9aff"
                }
              }, {
                "featureType": "subway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffffff"
                }
              }, {
                "featureType": "continent",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "continent",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "continent",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "continent",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "city",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "city",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "city",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "city",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "town",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "town",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "town",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#454d50ff"
                }
              }, {
                "featureType": "town",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffffff"
                }
              }, {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "poilabel",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "districtlabel",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "road",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "district",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "poilabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "poilabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "poilabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "manmade",
                "elementType": "geometry",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "districtlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffffff"
                }
              }, {
                "featureType": "entertainment",
                "elementType": "geometry",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "shopping",
                "elementType": "geometry",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "nationalway",
                "stylers": {
                  "level": "6",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "stylers": {
                  "level": "7",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "stylers": {
                  "level": "8",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "stylers": {
                  "level": "9",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "stylers": {
                  "level": "10",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "6",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "7",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "8",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "9",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "10",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "6",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "7",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "8",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "9",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "10",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "cityhighway",
                "stylers": {
                  "level": "6",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "stylers": {
                  "level": "7",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "stylers": {
                  "level": "8",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "stylers": {
                  "level": "9",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "6",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "7",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "8",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "9",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "6",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "7",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "8",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "9",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "subwaylabel",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "subwaylabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "tertiarywaysign",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "tertiarywaysign",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "provincialwaysign",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "provincialwaysign",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "nationalwaysign",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "nationalwaysign",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "highwaysign",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "highwaysign",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "village",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "district",
                "elementType": "labels.text",
                "stylers": {
                  "fontsize": "20"
                }
              }, {
                "featureType": "district",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "district",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "country",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "country",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "tertiaryway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "tertiaryway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff10"
                }
              }, {
                "featureType": "provincialway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "provincialway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "highway",
                "elementType": "labels.text",
                "stylers": {
                  "fontsize": "20"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels.text",
                "stylers": {
                  "fontsize": "20"
                }
              }, {
                "featureType": "provincialway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "provincialway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "provincialway",
                "elementType": "labels.text",
                "stylers": {
                  "fontsize": "20"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels.text",
                "stylers": {
                  "fontsize": "20"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "estate",
                "elementType": "geometry",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "tertiaryway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "tertiaryway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "fourlevelway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "fourlevelway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "scenicspotsway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "scenicspotsway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "universityway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "universityway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "vacationway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "vacationway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "fourlevelway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "fourlevelway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "fourlevelway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "transportationlabel",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "transportationlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "transportationlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "transportationlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "educationlabel",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "educationlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "educationlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "educationlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "transportation",
                "elementType": "geometry",
                "stylers": {
                  "color": "#113549ff"
                }
              }, {
                "featureType": "airportlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "airportlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "scenicspotslabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "scenicspotslabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "medicallabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "medicallabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "medicallabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "scenicspotslabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "airportlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "entertainmentlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "entertainmentlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "entertainmentlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "estatelabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "estatelabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "estatelabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "businesstowerlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "businesstowerlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "businesstowerlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "companylabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "companylabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "companylabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "governmentlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "governmentlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "governmentlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "restaurantlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "restaurantlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "restaurantlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "hotellabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "hotellabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "hotellabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "shoppinglabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "shoppinglabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "shoppinglabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "lifeservicelabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "lifeservicelabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "lifeservicelabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "carservicelabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "carservicelabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "carservicelabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "financelabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "financelabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "financelabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "otherlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "otherlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "otherlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "manmade",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "manmade",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "transportation",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "transportation",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "education",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "education",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "medical",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "medical",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "scenicspots",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "scenicspots",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }]
            }
        )
    },

    //获取动火点在地图上的位置
    getPoint(){
      let data={
        //"companyId": "6145378898200578"
        "companyId": "6223154483595266"
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/project/list', data).then(res => {
            if (res.code === 200) {
              for(var item in res.data.projectsAndHotwork[0]){
                if (item == 'hotWorkPermits') {
                  delete res.data.projectsAndHotwork[0][item];
                }
              }
              let arr=res.data.projectsAndHotwork
              for (let i = 0; i < arr.length; i++) {
                let res = JSON.parse(
                    JSON.stringify(arr[i])
                        .replace(/latitude/g, 'lat')
                        .replace(/longitude/g, 'lng')
                );
                this.newPoints.push(res)
              }
            }
          }
      ).catch(error => {
        console.log('错误' + error)
      })
    },
    look(item){
      console.log(item)
      this.from = item
      this.from.position = {
        lng: item.lng,
        lat: item.lat
      }
      this.showInfoWindow = true;
    },
    infoWindowClose () {
      this.showInfoWindow = false
    },
    infoWindowOpen (item) {
      this.showInfoWindow = true
    },
    //进入项目端大屏
    jumpToProjectScreen(project){
      console.log(project)
      window.open("https://firemanage.igongdi.top/#/projectSiteScreen?projectCode="+project.projectCode, '_blank')
      // window.open("http://localhost:8081/#/projectSiteScreen?projectCode="+project.projectCode, '_blank') // 本地测试
    },
    // part1
    //获取今日告警
    getTodayAlarm(){
      this.todayAlarmList = []
      let data={
        //"companyId": "6145378898200578"
        "companyId": this.curCompany.companyId,
        'type': this.active
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/alarm/statis', data).then(res => {
          if (res.code === 200) {
            let arr=res.data
            let sum=0
            let percent = 0
            if(res.data && res.data.length>0){
              for(let j in arr){
                sum+=arr[j].count
              }
              for(let i in arr){
                if(arr[i].alarmMessage!=="未监测到动火人员"){
                  // percent = (arr[i].count/sum * 100).toFixed(0)

                  // this.todayAlarmList.push({
                  //   name: this.filterAlarmName(arr[i].alarmMessage)+' ' + percent +"%",
                  //   value: arr[i].count
                  // })

                  this.todayAlarmList.push({
                    name: this.filterAlarmName(arr[i].alarmMessage),
                    value: arr[i].count
                  })
                }
              }
              this.todayAlarmList.push({
                name: '未佩戴安全带',
                value: 25
              })
            }else{
              this.todayAlarmList=[]
            }
            this.getTodayAlarmChart()
          }
        }
      ).catch(error => {
        console.log('错误' + error)
      })
    },
    filterAlarmName(str){
      let filterArr=["报警！","！"]
      let newStr = ''
      newStr = str
      if(str && str!==''){
        filterArr.forEach(item => {
          if(newStr.includes(item)){
            newStr = newStr.replace(item, '')
          }
        })
      }else{
        newStr = ''
      }
      return newStr
    },
    filterCompanyName(str){
      let filterArr=["有限公司","股份有限公司"]
      let newStr = ''
      newStr = str
      if(str && str!==''){
        filterArr.forEach(item => {
          if(newStr.includes(item)){
            newStr = newStr.replace(item, '')
          }
        })
      }else{
        newStr = ''
      }
      
      return newStr
    },
    handleChangeCompanyCommand(command){
      console.log(command);
      this.current = 0 // 切换到地图看板
      this.curCompany = command
      this.publicData()
      // 获取地图看板数据
      this.getPoint()

    },
    publicData(){
      this.getTodayAlarm() // 告警统计
      this.getOperatorManage() // 作业人员管理
      this.getHotworkDynamicCount() // 动火作业动态统计
      this.getDeviceStatis() // "动火机器人"管理
      this.getKeyArea() // 获取重点管控区域信息
    },
    countPercent(val1, val2){
      let num = val1/val2*100
      return parseFloat(num.toFixed(2)); 
    },
    formatRobotDistribute(row){
      return () => {
        return row ? row : 0;
      };
    },
    // 数据看板-本周作业活动分析
    getWeekFireWorkAnalysis(){
      let data = {
        'companyId': this.curCompany.companyId,
        'projectCode':''
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/fireWork/analysis/list', data).then(res => {
        if (res.code === 200) {
          this.weekFireWorkAnalysis=res.data
        }
      })
    },
    // tab切换
    changeTab(val){
      this.current = val
      this.clearAllVideo()
      if(val == 0){
        this.getPoint() // 获取地图看板数据
      }else if(val == 1){
        this.getWeekFireWorkAnalysis()
      }else{
        this.getVideo()
      }
    },
    // 清空所有视频信息：用于切换标签时停止监控视频的播放
    clearAllVideo(){
      this.newVideoList=[]
    },
    // tab2切换
    changeTab2(val){
      this.tCurrent = val
      this.getStackedAreaChart(val)
    },
    // 获取本年作业活动分析
    getYearBarChart(){
      let myChart = this.$echarts.init(document.getElementById('yearBarChart'));
      let option = {
        grid:{
          left:'15%',
          top: '1%',    // 调整顶部的间距
          bottom: '1%', // 调整底部的间距
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'value',
          splitLine:{show:false},
          axisLabel:{show:false},
          axisTick:{show:false},
          axisLine:{show:false}
        },
        yAxis: {
          type: 'category',
          data: ['作业人员活跃度', '审核率', '每日作业闭环率', '作业确认点位数', '人均出勤天数'],
          splitLine:{show:false},
          axisLabel:{
            show:true,
            interval: 0, // 强制显示所有标签
            textStyle: {
                color: '#fff', // 文字颜色
                fontSize: 14 // 文字大小
            },
          },
          axisTick:{show:false},
          axisLine:{show:false}
        },
        series: [
          {
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            label: {
              show: true // 显示所有标签
            },
            showBackground: true,
            backgroundStyle: { // 柱字的背景色
              color: 'rgba(180, 180, 180, 0.2)'
            },
            // barGap: '20%', // 设置条间距为柱状图宽度的20%
            barWidth: 14,
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: '#00CBD4' },
                // { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#00A3FE' }
              ]),
              borderRadius: [11, 11, 11, 11]
            },
            // emphasis: {
            //   itemStyle: {
            //     color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: '#2378f7' },
            //       { offset: 0.7, color: '#2378f7' },
            //       { offset: 1, color: '#83bff6' }
            //     ])
            //   }
            // },
            // label: { // 显示标签值
            //   show:true
            // }
          }
        ]
      };
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize',function() {myChart.resize()});
    },
    // 获取本周作业活动分析
    getWeekBarChart(){
      let myChart = this.$echarts.init(document.getElementById('weekBarChart'));
      let option = {
        grid:{
          left:'28%',
          top: '1%',    // 调整顶部的间距
          bottom: '1%', // 调整底部的间距
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'value',
          splitLine:{show:false},
          axisLabel:{show:false},
          axisTick:{show:false},
          axisLine:{show:false},
          // max: 1000,
        },
        yAxis: {
          type: 'category',
          // data: ['今日出勤最多的公司', '动火作业最多的项目', '作业票最多的公司', '焊工最多的公司'],
          data: ['上海俊令建筑劳务有限公司', '上海川平设备安装工程有限公司', '上海熙凯建设工程有限公司', '上海东服建筑有限公司'],
          splitLine:{show:false},
          axisLabel:{
            show:true,
            interval: 0, // 强制显示所有标签
            textStyle: {
                color: '#fff', // 文字颜色
                fontSize: 14 // 文字大小
            },
          },
          axisTick:{show:false},
          axisLine:{show:false}
        },
        series: [
          {
            data: [120, 200, 150, 80],
            type: 'bar',
            showBackground: true,
            backgroundStyle: { // 柱字的背景色
              color: 'rgba(180, 180, 180, 0.2)'
            },
            barWidth: 18,
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: '#22b6ed' },
                // { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#3fE279' }
              ]),
              borderRadius: [11, 11, 11, 11], // 柱状改为圆角
            },
            // emphasis: {
            //   itemStyle: {
            //     color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: '#2378f7' },
            //       { offset: 0.7, color: '#2378f7' },
            //       { offset: 1, color: '#83bff6' }
            //     ])
            //   }
            // },
            label: { // 显示标签值
              show:true,
              // formatter: "{a}-{b}-{c}",
              textStyle: {
                  color: '#FFF', // 文字颜色
                  fontSize: 14 // 文字大小
              },
            }
          }
        ]
      };
      setTimeout(function(){
        var width = myChart.getWidth();
        var opt = myChart.getOption();
        var grid = opt.grid[0];
        var right = grid.right;
        // console.log(right);
        var left = grid.left;
        right = width*parseFloat(right)/100;
        left = width*parseFloat(left)/100;
        var x = width-left-right;
        myChart.setOption({
            series:[{
                label:{
                    show:true,
                    position:'left',
                    offset:[x+50,0]
                }
            }]
        })
      })
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize',function() {myChart.resize()});
    },
    // 获取今日告警统计
    getTodayAlarmChart(){
      let myChart = this.$echarts.init(document.getElementById('todayAlarm_chart'));
      let option = {
        title: {
          text: this.todayAlarmList && this.todayAlarmList.length>0 ? "" : "暂无数据",
          subtext: "",
          itemGap: 4, // 调整主副标题之间的间距
          x: '18%',
          y: '35%',
          textStyle: {
            "color": "#fff",
            "fontSize": 12,
            "fontWeight": 500,
            "lineHeight": 1,
          },
          subtextStyle: {
            "color": "#B8B8B8",
            "fontSize": 10,
            "fontWeight": 400,
          }
        },
        tooltip: {
          trigger: 'item'
        },
        color:['#4669ea','#97f570','#ffee4b', "#ff5c5c","#6ee1ff","#2bb16b", "#ff8444", "#a555ce", "#ff7af5", "#ffa9a9"], // 自定义颜色
        legend: { // 图例设置
          itemHeight: 8,
          itemWidth: 8,
          orient: 'vertical', // 图例排列，垂直
          right: 0,
          type: 'scroll', // 图例较多时，可以使用可滚动翻页的图例
          textStyle: { //图例文字的样式
            color: '#ffffff',
            fontSize: 12,
            fontWeight: 400,
          },
          formatter: function(name){
            // 查找对应系列的数据和总数据
            let total = 0;
            for (let i=0;i<option.series[0].data.length;i++){
              total+=option.series[0].data[i].value;
            }
            for (let j=0;j<option.series[0].data.length;j++){
              let dpercent=(option.series[0].data[j].value/total*100).toFixed(2)+'%'
              if (option.series[0].data[j].name===name){
                return `${name}  ${dpercent}`
              }
            }
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: [35, 75],
            center: ['25%', '45%'],
            // roseType: 'area',
            itemStyle: {
              // borderRadius: 8
            },
            data: this.todayAlarmList,
            label: {
                show: false  // 隐藏标签
            },
            labelLine: {
                show: false  // 隐藏标签线
            }
          }
        ]
      };
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize',function() {myChart.resize()});
    },
    // 获取工程公司列表 雷达图
    getStackedAreaChart(val){
      console.log(val)
      let myChart = this.$echarts.init(document.getElementById('stackedAreaChart'));
      let option = {
        title: {
          text: ''
        },
        color: [this.gcColorList[val]],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
              
            }
          }
        },
        legend: {
          show: false,
          // data: [gcList[val].name],
          textStyle: { //图例文字的样式
            color: '#BABEC9',
            fontSize: 12,
            fontWeight: 400,
          },
          // selected: { // 刚开始显示第一个图例的线
          //   '作业人员活跃度': true,
          //   '作业作业闭环率': false,
          //   '人均出勤天数': false,
          //   '审核率': false,
          //   '人员合规': false,
          // },
          selectedMode: 'single', // 点击图例只显示一条线
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '8%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisLabel:{
              show:true,
              interval: 0, // 强制显示所有标签
              textStyle: {
                  color: '#fff', // 文字颜色
                  fontSize: 14 // 文字大小
              },
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel:{
              show:true,
              interval: 0, // 强制显示所有标签
              textStyle: {
                  color: '#fff', // 文字颜色
                  fontSize: 14 // 文字大小
              },
            },
          }
        ],
        series: [{
          name: this.gcList[val].name,
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: this.gcList[val].value
        }
          // {
          //   name: '作业人员活跃度',
          //   type: 'line',
          //   stack: 'Total',
          //   areaStyle: {},
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   data: [120, 132, 101, 134, 90, 230, 210, 132, 101, 134, 90]
          // },
          // {
          //   name: '作业作业闭环率',
          //   type: 'line',
          //   stack: 'Total',
          //   areaStyle: {},
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   data: [220, 182, 191, 234, 290, 330, 310, 182, 191, 234, 290, 330]
          // },
          // {
          //   name: '人均出勤天数',
          //   type: 'line',
          //   stack: 'Total',
          //   areaStyle: {},
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   data: [150, 232, 201, 154, 190, 330, 410, 232, 201, 154, 190, 330]
          // },
          // {
          //   name: '审核率',
          //   type: 'line',
          //   stack: 'Total',
          //   areaStyle: {},
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   data: [320, 332, 301, 334, 390, 330, 320, 332, 301, 334, 390, 330]
          // },
          // {
          //   name: '人员合规',
          //   type: 'line',
          //   stack: 'Total',
          //   // label: {
          //   //   show: true,
          //   //   position: 'top'
          //   // },
          //   areaStyle: {},
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   data: [820, 932, 901, 934, 1290, 1330, 1320, 932, 901, 934, 1290, 1330]
          // }
        ]
      };
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize',function() {myChart.resize()});
    },
    // 获取雷达图
    getRadarChart(){
      let myChart = this.$echarts.init(document.getElementById('radarChart'));
      let option = {
        // grid:{
        //   // left:'18%',
        //   top: '30px',    // 调整顶部的间距
        //   // bottom: '1%', // 调整底部的间距
        // },
        title: {
          left: 'center',
        },
        tooltip: {
          trigger: 'item'
        },
        color:['#8bc7fb','#FFA940','#1890FF'],
        legend: {
          show: false, // 隐藏图例
          top: 'bottom',
          textStyle: { //图例文字的样式
            color: '#E5E4E5',
            fontSize: 14,
            fontWeight: 400,
          },
        },
        radar: {
          // shape: 'circle',
          indicator: [
            // { name: '今日出勤最多的公司', max: 6500 },
            // { name: '动火作业最多的项目', max: 16000 },
            // { name: '作业票最多的公司', max: 30000 },
            // { name: '焊工最多的公司', max: 38000 },
            { name: '人均出勤天数', max: 6500 },
            { name: '作业确认点位数', max: 16000 },
            { name: '每日作业闭环率', max: 30000 },
            { name: '审核率', max: 38000 },
            { name: '作业人员活跃度', max: 38000 },
          ],
          center: ['50%', '55%'],
          // 刻度标签设置
          // axisLabel: {
          //     show: true, // 显示刻度标签
          //     fontSize: 12,
          //     color: 'white'
          // },
          
          splitArea: { // 雷达图分隔区域颜色
            areaStyle: {
              color: ['#0d1e47', '#08366b'],
              shadowColor: 'rgba(0, 0, 0, 0.2)',
              shadowBlur: 10
            }
          },
          axisLine:{ //y轴
            show:false, // 隐藏坐标轴
          },
          splitLine: { // 雷达图分隔线颜色
            lineStyle: {
              color: 'rgba(0, 105, 177, 1)'
            }
          }

        },
        series: [
          {
            name: '',
            type: 'radar',
            center: ['50%', '50%'],
            data: this.radarList,
            lineStyle: {
              // 绘制数据的边缘颜色
              width: 1,
              color: '#d9f7ff',
            },
            label: {
              normal: {
                show: false,
                // formatter: '{d}%',
                formatter: '{c}',
                color:'#FFFFFF',
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
          }
        ]
      };
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize',function() {myChart.resize()});
    },
    // 超龄预警
    getOverageWarning(){
      let myChart = this.$echarts.init(document.getElementById('overageWarningChart'));
      let option = {
        title: {
          text: this.operatorManageInfo.totalCount,
          subtext: "总人数",
          itemGap: 4, // 调整主副标题之间的间距
          x: 'center',
          y: 'center',
          textStyle: {
            "color": "#fff",
            "fontSize": 18,
            "fontWeight": 500,
            "lineHeight": 1,
          },
          subtextStyle: {
            "color": "#B8B8B8",
            "fontSize": 10,
            "fontWeight": 400,
          }
        },
        tooltip: {
          trigger: 'item'
        },
        color:['#FF4D4F','#FFA940','#1890FF'],
        legend: {
          show: false, // 隐藏图例
          top: 'bottom',
          textStyle: { //图例文字的样式
            color: '#E5E4E5',
            fontSize: 14,
            fontWeight: 400,
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['50%', '50%'],
            data: this.overageWarningList,
            // 设置起始角度为180度，即从垂直方向开始逆时针旋转
            // startAngle: -90,
            itemStyle: {
              normal: {
                color: function(params){
                  // 自定义图例颜色数组
                  let colorList=[
                    "#0EC48B", "#DBDADA", "#00f"
                  ];
                  return colorList[params.dataIndex % colorList.length];
                }
              }
            },
            label: {
              normal: {
                show: true,
                // formatter: '{d}%',
                formatter: '{b}\n{c}', // 标签文本格式器，{b} 表示数据项名称，{c} 表示数据值，{d} 表示百分比, \n 换行
                color:'#FFFFFF',
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize',function() {myChart.resize()});
    },
    // 证件延期
    getCertificateDelayWarning(){
      let myChart = this.$echarts.init(document.getElementById('certificateDelayChart'));
      let option = {
        title: {
          text: this.operatorManageInfo.attendanceTotalCount,
          subtext: "今日出勤",
          itemGap: 4, // 调整主副标题之间的间距
          x: 'center',
          y: 'center',
          textStyle: {
            "color": "#fff",
            "fontSize": 18,
            "fontWeight": 500,
            "lineHeight": 1,
          },
          subtextStyle: {
            "color": "#B8B8B8",
            "fontSize": 10,
            "fontWeight": 400,
          }
        },
        tooltip: {
          trigger: 'item'
        },
        color:['#FF4D4F','#FFA940','#1890FF'],
        legend: {
          show: false, // 隐藏图例
          top: 'bottom',
          textStyle: { //图例文字的样式
            color: '#E5E4E5',
            fontSize: 14,
            fontWeight: 400,
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['50%', '50%'],
            data: this.certificateDelayList,
            itemStyle: {
              normal: {
                color: function(params){
                  // 自定义图例颜色数组
                  let colorList=[
                    "#0091FF", "#DBDADA", "#00f"
                  ];
                  return colorList[params.dataIndex % colorList.length];
                }
              }
            },
            label: {
              normal: {
                show: true, // 显示图表上的标签
                // formatter: '{d}%',
                formatter: '{b}\n{c}',
                color:'#FFFFFF',
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize',function() {myChart.resize()});
    },
    
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "";
      } else {
        return "warning-row";
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
  @import "../../../styles/base.styl"
  
  .enterprise-site-screen-container
    
    .black-and-white
      filter: grayscale(75%);

    .timeTabs
      padding 0
      margin 0
      margin-top 15px
      display flex
      font-size 12px
      .tab
        border solid 1px #bedaf9
        flex 1
        list-style none
        text-align center
        color #bedaf9
        cursor pointer
      .selected
        border solid 1px #bedaf9
        flex 1
        list-style none
        text-align center
        color #9DD8FD
        background: linear-gradient(0.00deg, rgb(26, 142, 203),rgba(0, 41, 92, 0) 100%);
        cursor pointer
      .tab:first-child,.selected:first-child
        border-right 0
      .tab:last-child,.selected:last-child
        border-left 0
    .centerTimeTabs
      padding 0
      margin 0
      margin-top -5px
      display flex
      font-size 12px
      .tab
        border solid 1px #bedaf9
        height 28px
        line-height 28px
        flex 1
        list-style none
        text-align center
        color #bedaf9
        cursor pointer
      .selected
        border solid 1px #bedaf9
        height 28px
        line-height 28px
        flex 1
        list-style none
        text-align center
        color #9DD8FD
        background: linear-gradient(0.00deg, rgb(26, 142, 203),rgba(0, 41, 92, 0) 100%);
        cursor pointer
      .tab:first-child,.selected:first-child
        border-right 0
      .tab:last-child,.selected:last-child
        border-left 0
    .map
      width: 100%;
      height: 534px;
    
    .logo-img
      position: absolute;
      left: 3vh;
      top: 29px;
    
    .logoDiv
      font-family: 'YouSetBlack', sans-serif;
      color: rgb(242, 246, 251);
      font-size: 42px;
      font-weight: 400;
      line-height: 55px;
      letter-spacing: 1px;
      text-align: center;
      text-shadow: 0 4px 4px rgba(0,0,0,0.25); /* 水平阴影 垂直阴影 模糊效果 阴影颜色  */
      z-index: 1;
      position: absolute;
      top: 15px;
      left:0;
      right:0;
    
    .bg-img-container
      display:flex;
      position: absolute;
      // height: 100%;
      top: 0;
      width: 100%;
      z-index: -1;
    .bg-img
      display:flex;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    
    .left,.right
      display: flex;
      flex: 0.55;
      margin-top: 11vh;
      // height: 86vh;
      flex-direction: column;
      // background: #ccc;
    .left
      padding-left 3.38%
    .right
      padding-right 3.38%
    .center
      flex:1;
      box-sizing: border-box;
      margin: 0 0.9%;
      margin-top: 11vh;
      height:86vh;
    
    .box-container
      box-sizing border-box
      border 1px solid rgb(42, 118, 212)
      padding 9px 18px
      margin-bottom 18px
      background linear-gradient(135.87deg, rgba(10, 24, 55, 0.72) 0%,rgba(21, 54, 134, 0.58) 98.055%)
      // min-height 297px
      .title
        font-family: 'FZZYJW2', sans-serif;
        height 33px
        background url("../../../assets/screen/title_bg02.png") no-repeat left top
        padding-left 37px
        box-sizing border-box
        span
          background: linear-gradient(180.00deg, rgb(255, 255, 255),rgb(42, 162, 212));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-size: 24px;
          font-weight: 400;
          line-height: 35px;
          letter-spacing: 2px;
          text-align: left;
  
      .content
        padding-top 16px

      .robot-line
        color #fff
        font-size 12px
        display flex
        flex-direction row
        .label
          width 120px
          text-align right
          padding 0 8px
          line-height 30px
        .list
          flex 1
          text-align left
          .outline
            display inline-block
            width 38px
            height 30px
            box-sizing border-box
            text-align center
            padding-top 2px
            // background-color #2ac5ff
            // background rgba(42,197,255,0.85)
            // background: radial-gradient(circle, #2ac5ff 100%, #ffffff 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,0.25) 0%, #2ac5ff 80%);
            border-radius 12px
            margin-right 6px
            margin-bottom 10px
          .off
            background-color #2a76d4
      // 修改表格样式start
      // 表格内背景颜色
      ::v-deep .el-table
        background-color: transparent;  // 背景透明
      // ::v-deep .el-table th,
      ::v-deep .el-table tr,
      ::v-deep .el-table td
        background-color: transparent;  // 背景透明
        border: 0px;
        color: #fff;  // 修改字体颜色
        // font-size: 24px;
        height: 5px;
      // 去掉最下面的那一条线
      .el-table::before
        height: 0px;
      // 修改表头样式
      ::v-deep .header-cell-class
        color: #50D1FF
        background: #0B2E63
  
      ::v-deep .el-table .el-table__body tr.el-table__row:nth-child(even)
        background-color: #0B2E63; /* 修改成你想要的颜色 */
      
      ::v-deep .el-table th.is-leaf
        border-bottom: 0; // 表头下的边
  
      // 修改表格样式end
      .alarm-container
        display flex
        flex-direction row
        .tab
          color #fff
          flex 1
          justify-content: space-around;
          height 63px
          line-height: 63px;
          vertical-align: middle;
          background url('../../../assets/screen/alarm-num-bg.png') no-repeat left center
          img
            vertical-align: middle
            margin-left: 18px;
            margin-right: 10px;
            flex: 1;
          .alarm-name
            color: #C6CEDB;
            font-size: 14px;
            flex: 1;
            // height 63px
            // line-height: 63px;
            // display: inline-block;
          .alarm-num
            color #FFFFFF
            font-size 28px
            font-weight 400
            flex 1
            padding-left 2px
            text-align center
    .tab-container
      display: flex;
      padding-left 101px;
      margin-bottom 4px
      li
        background: url('../../../assets/screen/center_button.png') no-repeat center center
        background-size: 100%
        width 177px
        height 60px
        line-height 60px
        text-align center
        font-size 20px
        font-weight: bold;
        line-height: -1px;
        letter-spacing: 2px;
        display: inline-block
        margin-right: 25px;
        span
          background: linear-gradient(180.00deg, rgb(255, 255, 255),rgb(83, 192, 238));
          -webkit-background-clip text
          -webkit-text-fill-color transparent
          background-clip text
          text-fill-color transparent
          cursor: pointer
        &.selected
          background: url('../../../assets/screen/center_button_select.png') no-repeat center center
          background-size: 100%
          span
            background: linear-gradient(180.00deg, rgb(255, 255, 255),rgb(246, 218, 91));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        &:hover
          background: url('../../../assets/screen/center_button_select.png') no-repeat center center
          background-size: 100%
          span
            background: linear-gradient(180.00deg, rgb(255, 255, 255),rgb(246, 218, 91));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
    .tab-normal-container
      display: flex;
      padding-left 101px
      li
        background: url('../../../assets/screen/tab.png') no-repeat center center
        background-size: 100%
        width 103px
        height 24px
        line-height 24px
        text-align center
        font-size 12px
        font-weight: 400;
        line-height: 24px;
        display: inline-block
        margin-right: 25px;
        span
          color #BABEC9
          cursor: pointer
        &.selected
          background: url('../../../assets/screen/tab_select.png') no-repeat center center
          background-size: 100%
          span
            color #9DD8FD
        &:hover
          background: url('../../../assets/screen/tab_select.png') no-repeat center center
          background-size: 100%
          span
            color #9DD8FD
    .robot-count-container
      color: #fff;
      padding: 0;
      margin: 0;
      li
        line-height: 46px;
        padding-bottom: 2px;
        background: url('../../../assets/screen/line_bottom.png') no-repeat center bottom
        list-style: none
        font-size 16px
        display: flex
        img
          margin-right 8px
          margin-top: 24px
        .label
          color #fff
          min-width 64px
        .num
          font-weight 40
          color #50D1FF
          font-family: 'YouSetBlack', sans-serif;
          text-align: center
          flex 1
        .unit
          color #BCBCBC
          width 32px
        &.total
          .num
            color #F0D544
    .qiehuan{
      top: 10px;
      right: 180px;
      width: 146px;
      height: 30px;
      line-height: 30px;
      z-index: 1;
      color: #fff;
      font-size: 14px;
      background: linear-gradient(270.00deg, rgb(37, 99, 171),rgba(0, 21, 42, 0) 100%);
      border:2px solid;
      border-image: linear-gradient(to right, #5FA9E9, #396D9B) 1;
      padding-left: 17px;
      margin-top: 25px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      float: right;
    }
    // xiala
    .xlcd {
      width: 80px;
      margin-top: -5px;
      margin-right: 6px; 
      float: right;
      background: linear-gradient(270.00deg, rgb(37, 99, 171),rgba(0, 21, 42, 0) 100%);
      border:1px solid #fff;
      // border-image: linear-gradient(to right, #5FA9E9, #396D9B) 1;
      // 修改input默认值颜色 兼容其它主流浏览器
      ::v-deep input::-webkit-input-placeholder {
          color: rgba(255, 255, 255, 0.50);
      }
      ::v-deep input::-moz-input-placeholder {
          color: rgba(255, 255, 255, 0.50);
      }
      ::v-deep input::-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.50);
      }
      // input框
      ::v-deep .el-select,
      ::v-deep .el-input,
      ::v-deep .el-input__inner {
          background-color: rgba(255, 255, 255, 0.04);
          color: rgba(255, 255, 255, 0.8);
          border: none; // 去掉边框
          // border-color: #XXXXXX // 默认边框的颜色
          text-align: left;
          border-radius: 4px;
      }
  
      // 选中时边框颜色
      // ::v-deep .el-input__inner:focus{
      //     border-color: #XXXXXX;
      // }
  
      // 鼠标悬浮时 input框颜色
      ::v-deep .el-input__inner:hover{
          background-color: rgba(255, 255, 255, 0.12);
      }
  
      // input框 右侧的箭头
      ::v-deep .el-select .el-input .el-select__caret {
          color: rgba(255, 255, 255, 0.50);
      }
  
      // option选项 上面的箭头
      ::v-deep .el-popper[x-placement^="bottom"] .popper__arrow::after {
          border-bottom-color: rgba(21,54,134,0.58);
          z-index: 9999;
      }
      ::v-deep .popper__arrow {
          border: none;
      }
      // option选项 最外层
      ::v-deep .el-select-dropdown {
          border: none !important;
          background: rgba(21,54,134,0.58) !important;
          
          z-index: 9999;
      }
      // option选项 文字样式
      ::v-deep .el-select-dropdown__item {
          color: rgba(255, 255, 255, 0.8) !important;
          font-size:12px !important;
          z-index: 9999;
      }
      ::v-deep .el-select-dropdown__item.is-disabled{
        color: rgba(187, 187, 187, 0.80) !important;
      }
      ::v-deep .el-select-dropdown__item.selected span{
          color: rgba(255, 255, 255, 0.80) !important;
          z-index: 9999;
      }
      // 移入option选项 样式调整
      ::v-deep .el-select-dropdown__item.hover{
          background-color: rgba(255, 255, 255, 0.06);
          color: rgba(255, 255, 255, 0.80) !important;
          z-index: 9999;
      }
  
    // 下拉框垂直滚动条宽度
      ::v-deep .el-scrollbar__bar.is-vertical {
          width: 10px;
          top: 2px;
      }
      // 下拉框最大高度
      ::v-deep .el-select-dropdown__wrap {
          max-height: 200px;
      }
  
      // option选项 下面的箭头
      ::v-deep .el-popper[x-placement^="top"] .popper__arrow::after {
          border-top-color: rgba(43, 45, 55, 0.80);
          z-index: 9999;
      }
  
  }
  </style>
  <style lang="stylus">
  .enterprise-site-screen-container
    .el-progress-bar__outer
      background-color: rgba(196, 196, 196, 0.15)
  .el-popper.dropdownMenu {
    background-color: #2563ab;  // 背景颜色
    border: 1px solid; 
    border-image: linear-gradient(to right, #5fa9e9, #396d9b) 
    .el-dropdown-menu__item { // 下拉菜单文字颜色
      color: #fff !important;
    }
    .el-dropdown-menu__item:not(.is-disabled):hover {
      background: #2563ab !important;
    }
  }
  </style>